<template>
  <div>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="bg-gray-900">
      <div class="pt-12 sm:pt-16 lg:pt-20">
        <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="text-center">
            <h2 class="text-white md:text-center text-4xl lg:text-5xl font-bold leading-none sm:leading-tighter">
            A small price for more time and<br><!--, more sanity & <br>-->
            <span class="inline-flex relative text-white">
              <span aria-hidden="" class="absolute inset-0 bg-showman" style="transform: skewY(-1.3deg) scaleX(1.03) scaleY(1.05);"></span>
              <span class="relative">less stress</span>
            </span>
            at your next show.
            </h2>
            <p class="mt-4 text-xl font-light text-gray-300">
              Sign up today and find out just how easy running your livestock show can be!
            </p>
          </div>
        </div>
      </div>

      <div class="mt-12 sm:mt-12 pb-10 sm:pb-16 lg:mt-16">
        <div class="relative">
          <div class="absolute inset-0 h-3/4 bg-gray-900"></div>
          <div class="relative z-10 mx-auto px-4 sm:px-6 lg:px-0">
            <!--<div class="max-w-lg mx-auto space-y-4 lg:max-w-6xl lg:grid lg:grid-cols-12 lg:gap-5 lg:space-y-0">-->
            <div class="max-w-lg_ mx-auto lg:max-w-7xl_ lg:grid lg:grid-cols-4 lg:gap-5 px-4 xl:px-16">
              <div class="col-span-1  flex flex-col rounded-md shadow-lg overflow-hidden relative">

                <div class="px-8 pb-10 pt-20 text-white bg-pink-800">
                  <div>
                    <h3 class="text-left text-3xl font-bold text-white" id="tier-growth">Starter</h3>
                    <p class="text-base font-thin text-pink-100">Great for small or first time shows.</p>

                  </div>
                  <div class="mt-4 flex items-center text-4xl font-medium justify-start">
                    $299
                  </div>
                  <p class="mt-1 text-sm text-pink-100 font-light h-12">Price includes 150 Entries. Additional entries over 150 are $1/entry.<br>
                    <span class="text-xs font-medium">(Your plan will automatically be bumped up to "Standard" after 250 entries)</span>
                  </p>
                  <!--<p class="mt-1 text-base text-blue-100 font-light h-20">All the basic tools you need to run a livestock show. Ditch the headaches and get ready to smile during your next weekend show.</p>-->

                </div>
                <div class="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-100 space-y-6 sm:p-6 sm:pt-6">
                  <div>
                  <p class="font-medium text-lg mb-8">The tool you need to run your show:</p>
                    <ul role="list" class="space-y-4">
                      <!--<li class="flex items-start">
                        <div class="flex-shrink-0">
                          <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <p class="ml-3 text-base text-gray-700">Includes 1000 Entries.<br><span class="text-sm font-semibold">*Additional entries over 1000 are $0.4030/entry*</span></p>
                      </li>-->
                      <li class="flex items-start">
                        <div class="flex-shrink-0">
                          <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <p class="ml-3 text-base text-gray-700">All the online tools you need to run your show.</p>
                      </li>
                      <li class="flex items-start">
                        <div class="flex-shrink-0">
                          <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <p class="ml-3 text-base text-gray-700">Fully customizable entry form to collect info on apparel, pens, bedding and much more.</p>
                      </li>
                      <li class="flex items-start">
                        <div class="flex-shrink-0">
                          <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <p class="ml-3 text-base text-gray-700">Support for up to 2 species.</p>
                      </li>
                      <li class="flex items-start">
                        <div class="flex-shrink-0">
                          <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <p class="ml-3 text-base text-gray-700">1 hr show setup call with a show specialist</p>
                      </li>
                      <li class="flex items-start">
                        <div class="flex-shrink-0">
                          <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <p class="ml-3 text-base text-gray-700">Phone number for show day support</p>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div class="rounded-md shadow">
                      <a onclick="fathom.trackGoal('PPBJ1VUV', 0);" href="/shows/#/new-show-signup" class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-showman hover:bg-orange-500">
                        Create My Show
                      </a>
                    </div>
                    <p class="mt-3 text-xs text-center font-medium">Online payments have a 3.5% processing fee that can either be built into your entry fees or passed on directly to exhibitors.</p>
                  </div>
                </div>
              </div>
              <div class="col-span-1  flex flex-col rounded-md shadow-lg overflow-hidden relative">

                <div class="px-8 pb-10 pt-20 text-white bg-blue-700">
                  <div>
                    <h3 class="text-left text-3xl font-bold text-white" id="tier-growth">Standard</h3>
                    <p class="text-base font-thin text-blue-200">Weekend Jackpot & Breed Shows</p>

                  </div>
                  <div class="mt-4 flex items-center text-4xl font-medium justify-start">
                    $399
                  </div>
                  <p class="mt-1 text-sm text-blue-100 font-light h-12">Price includes 1000 Entries. Additional entries over 1000 are $0.40/entry</p>
                  <!--<p class="mt-1 text-base text-blue-100 font-light h-20">All the basic tools you need to run a livestock show. Ditch the headaches and get ready to smile during your next weekend show.</p>-->
                </div>
                <div class="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-100 space-y-6 sm:p-6 sm:pt-6">
                  <div>
                   <p class="font-medium text-lg mb-8">All the goodness of Starter, and:</p>
                    <ul role="list" class="space-y-4">
                      <!--<li class="flex items-start">
                        <div class="flex-shrink-0">
                          <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <p class="ml-3 text-base text-gray-700">Includes 1000 Entries.<br><span class="text-sm font-semibold">*Additional entries over 1000 are $0.4030/entry*</span></p>
                      </li>-->

                      <li class="flex items-start">
                        <div class="flex-shrink-0">
                          <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <p class="ml-3 text-base text-gray-700">1000 entries included; (Additional entries over 1000 supported at $0.40/entry)</p>
                      </li>
                      <li class="flex items-start">
                        <div class="flex-shrink-0">
                          <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <p class="ml-3 text-base text-gray-700">Support for up to 5 species.</p>
                      </li>
                      <li class="flex items-start">
                        <div class="flex-shrink-0">
                          <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <p class="ml-3 text-base text-gray-700"><span class="font-bold">Extra Support and setup assistance.</span> Including an extra hour of premium support.</p>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div class="rounded-md shadow">
                      <a onclick="fathom.trackGoal('PPBJ1VUV', 0);" href="/shows/#/new-show-signup" class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-showman hover:bg-orange-500">
                        Create My Show
                      </a>
                    </div>
                    <p class="mt-3 text-xs text-center font-medium">Online payments have a 3.5% processing fee that can either be built into your entry fees or passed on directly to exhibitors.</p>
                  </div>
                </div>
              </div>

              <div class="col-span-1 flex flex-col rounded-md shadow-lg overflow-hidden">
                <div class="px-8 pb-10 pt-20 text-white bg-blue-900">
                  <div>
                    <h3 class="text-left text-3xl font-bold text-white" id="tier-growth">Elite</h3>
                    <p class="text-base font-thin text-blue-200">Fairs, Festivals & Expos</p>
                  </div>
                  <div class="mt-4 flex items-center text-4xl font-medium justify-start">
                    $699
                  </div>
                  <p class="mt-1 text-sm text-blue-100 font-light h-12">Price includes 1000 Entries. Additional entries over 1000 are $0.40/entry</p>

                  <!--<p class="mt-1 text-base text-blue-100 font-light h-20">The same tools you know and love plus extra support for those events that require more detailed setup and support.</p>-->
                </div>
                <div class="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-100 space-y-6 sm:p-6 sm:pt-6">
                  <div>
                    <p class="font-medium text-lg mb-8">All the goodness of Standard, and:</p>
                    <ul role="list" class="space-y-4">
                      <!--<li class="flex items-start">
                        <div class="flex-shrink-0">
                          <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <p class="ml-3 text-base text-gray-700">Includes 1000 Entries.<br><span class="text-sm font-semibold">*Additional entries over 1000 are $0.30/entry*</span></p>
                      </li>

                      <li class="flex items-start">
                        <div class="flex-shrink-0">
                          <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <p class="ml-3 text-base text-gray-700">Unlimited Exhibitors</p>
                      </li>
                      <li class="flex items-start">
                        <div class="flex-shrink-0">
                          <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <p class="ml-3 text-base text-gray-700">Fully customizable entry form to collect info on apparel, pens, bedding and much more.</p>
                      </li>-->
                      <li class="flex items-start">
                        <div class="flex-shrink-0">
                          <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <p class="ml-3 text-base text-gray-700">Support for <span class="font-bold">unlimited</span> species</p>
                      </li>
                      <li class="flex items-start">
                        <div class="flex-shrink-0">
                          <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <p class="ml-3 text-base text-gray-700 font-bold">Support for Static Exhibits. (Farm & Graden, Baking, Art etc.)</p>
                      </li>
                      <li class="flex items-start">
                        <div class="flex-shrink-0">
                          <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <p class="ml-3 text-base text-gray-700"><span class="font-bold">Extra Support and setup assistance.</span> Including 2 extra hours of preimum support.</p>
                      </li>
                      <!--<li class="flex items-start">
                        <div class="flex-shrink-0">
                          <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <p class="ml-3 text-base text-gray-700">Phone number for show day support</p>
                      </li>-->
                    </ul>
                  </div>
                  <div>
                    <div class="rounded-md shadow">
                      <a onclick="fathom.trackGoal('PPBJ1VUV', 0);" href="/shows/#/new-show-signup" class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-showman hover:bg-orange-500">
                        Create My Show
                      </a>
                    </div>
                    <p class="mt-3 text-xs text-center font-medium">Online payments have a 3.5% processing fee that can either be built into your entry fees or passed on directly to exhibitors.</p>
                  </div>
                </div>
              </div>

              <div class="col-span-1 flex flex-col rounded-md shadow-lg overflow-hidden border-4 border-gray-700">
                <div class="px-8 pb-10 pt-20 text-black bg-white">
                  <div>
                    <h3 class="text-left text-3xl font-bold text-black" id="tier-growth">Enterprise</h3>
                    <p class="text-base text-black font-thin">The platform for shows of all sizes.</p>

                  </div>
                  <div class="mt-4 flex items-center text-3xl font-semibold justify-start">
                    Starting at $2000 <span class="text-4xl">&nbsp;</span>
                  </div>
                  <p class="mt-1 text-base text-black-100 font-light h-12">
                    <a data-savvycal-embed href="https://savvycal.com/showman/enterprise" class="underline">Contact Sales</a> for pricing estimation.
                  </p>
                </div>
                <div class="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-100 space-y-6 sm:p-6 sm:pt-6">
                  <div class="">
                    <p class="font-medium text-lg mb-8">All the goodness of Elite, and:</p>
                    <p>The potential for completing extra approval requirements. We know some organizations have requirements for software and vendors. Looking forward to chatting!</p>
                  </div>
                  <div class="">
                    <div class="rounded-md shadow">
                      <a data-savvycal-embed href="https://savvycal.com/showman/enterprise" class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-showman hover:bg-orange-500">Contact Sales</a>
                    </div>
                    <p class="mt-3 text-xs text-center font-medium">Online payments have a 3.5% processing fee that can either be built into your entry fees or passed on directly to exhibitors.</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <!--<div class="mt-12 sm:mt-12 sm:pb-16 lg:mt-16">
        <div class="relative">
          <div class="absolute inset-0 h-3/4 bg-gray-900"></div>
          <div class="relative z-10 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex flex-row space-x-4">
              <div class="w-1/4 mt-10 lg:mt-0 lg:col-start-3">
                <div class="relative z-10 rounded-lg shadow-xl">
                  <div class="bg-white rounded-t-lg rounded-b-lg px-6 pt-12 pb-10">
                    <div>
                      <h3 class="text-center text-3xl font-semibold text-gray-900 sm:-mx-6" id="tier-growth">
                        Starter
                      </h3>
                      <div class="mt-4 flex items-center justify-center">
                        <span class="px-3 flex items-start text-6xl tracking-tight text-gray-900 sm:text-6xl">
                          <span class="mt-2 mr-2 text-4xl font-medium">
                            $
                          </span>
                          <span class="font-extrabold">
                            299
                          </span>
                        </span>
                        <span class="text-2xl font-medium text-gray-700">
                          /show
                        </span>
                      </div>
                      <div class="mt-10">
                        <div class="rounded-md shadow">
                          <a onclick="fathom.trackGoal('PPBJ1VUV', 0);" href="/shows/#/new-show-signup" class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-showman hover:bg-orange-500">
                            Get started
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="w-1/4 mt-10 lg:mt-0 lg:col-start-3">
                <div class="relative z-10 rounded-lg shadow-xl">
                  <div class="pointer-events-none absolute inset-0 rounded-lg border-2 border-showman" aria-hidden="true"></div>
                  <div class="absolute inset-x-0 top-0 transform translate-y-px">
                    <div class="flex justify-center transform -translate-y-1/2">
                      <span class="inline-flex rounded-full bg-showman px-4 py-1 text-sm font-semibold tracking-wider uppercase text-white">
                        Most Popular
                      </span>
                    </div>
                  </div>
                  <div class="bg-white rounded-t-lg rounded-b-lg px-6 pt-12 pb-10">
                    <div>
                      <h3 class="text-center text-3xl font-semibold text-gray-900 sm:-mx-6" id="tier-growth">
                        Standard
                      </h3>
                      <div class="mt-4 flex items-center justify-center">
                        <span class="px-3 flex items-start text-6xl tracking-tight text-gray-900 sm:text-6xl">
                          <span class="mt-2 mr-2 text-4xl font-medium">
                            $
                          </span>
                          <span class="font-extrabold">
                            399
                          </span>
                        </span>
                        <span class="text-2xl font-medium text-gray-700">
                          /show
                        </span>
                      </div>
                      <div class="mt-10">
                        <div class="rounded-md shadow">
                          <a onclick="fathom.trackGoal('PPBJ1VUV', 0);" href="/shows/#/new-show-signup" class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-showman hover:bg-orange-500">
                            Get started
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-1/4 mt-10 lg:mt-0 lg:col-start-3">
                <div class="relative z-10 rounded-lg shadow-xl">
                  <div class="bg-white rounded-t-lg rounded-b-lg px-6 pt-12 pb-10">
                    <div>
                      <h3 class="text-center text-3xl font-semibold text-gray-900 sm:-mx-6" id="tier-growth">
                        Elite
                      </h3>
                      <div class="mt-4 flex items-center justify-center">
                        <span class="px-3 flex items-start text-6xl tracking-tight text-gray-900 sm:text-6xl">
                          <span class="mt-2 mr-2 text-4xl font-medium">
                            $
                          </span>
                          <span class="font-extrabold">
                            699
                          </span>
                        </span>
                        <span class="text-2xl font-medium text-gray-700">
                          /show
                        </span>
                      </div>
                      <div class="mt-10">
                        <div class="rounded-md shadow">
                          <a onclick="fathom.trackGoal('PPBJ1VUV', 0);" href="/shows/#/new-show-signup" class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-showman hover:bg-orange-500">
                            Get started
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="w-1/4 mt-10 lg:mt-0 lg:col-start-3">
                <div class="relative z-10 rounded-lg shadow-xl">
                  <div class="bg-gray-700 rounded-t-lg rounded-b-lg px-6 pt-12 pb-10">
                    <div>
                      <h3 class="text-center text-3xl font-semibold text-gray-100 sm:-mx-6" id="tier-growth">
                        Enterprise
                      </h3>
                      <div class="mt-4 flex flex-col items-center justify-center">
                        <span class="text-xl font-medium text-gray-100">
                          Starting at
                        </span>
                        <span class="px-3 flex items-start text-2xl tracking-tight text-gray-100 sm:text-2xl">
                          <span class="font-extrabold">
                            $2000
                          </span>
                        </span>
                      </div>
                      <div class="mt-10">
                        <div class="rounded-md shadow">
                          <a onclick="fathom.trackGoal('PPBJ1VUV', 0);" href="/shows/#/new-show-signup" class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-showman hover:bg-orange-500">
                            Contact Sales
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="bg-showman py-10">
        <div class="max-w-5xl md:mx-auto mx-3">
          <div class="border-b-2 border-gray-200 mb-6">
            <h4 class="flex-shrink-0 pr-4 text-xl tracking-wider font-semibold uppercase text-white">
              Additional Packages
            </h4>
          </div>
          <ShowmanAuctionPricing id="auction"/>
        </div>
      </div>

      <div class="max-w-7xl mx-auto bg-gray-900 mt-12">
        <div class="sm:flex sm:flex-col sm:align-center">
          <h2 class="text-3xl font-extrabold text-white sm:text-center">Compare Features</h2>
          <p class="mt-5 text-xl text-gray-300 sm:text-center">Choose the plan that's right for your show</p>
        </div>
        <div class="mt-12 overflow-hidden_ px-10">
          <table class="min-w-full divide-y divide-gray-200 ">
            <thead class=" bg-gray-900 sticky top-0 z-50 ">
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                </th>
                <th v-for="level in pricing_levels" :key="level.name" scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-300 uppercase tracking-wider">
                  <div :class="{'bg-showman': level.name === 'Standard', 'bg-gray-700': level.name !== 'Standard'}" class="rounded-lg p-4 relative">
                    <div v-if="level.name === 'Standard'" class="absolute top-0 right-0 -mt-3 -mr-3 px-3 py-1 bg-yellow-400 text-xs font-medium text-gray-900 rounded-full">
                      Most Popular
                    </div>
                    <h3 class="text-xl font-bold text-white">{{ level.name }}</h3>
                    <p class="mt-2 text-2xl font-extrabold text-white">${{ level.price }}</p>
                    <p class="text-sm text-gray-300">/show</p>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody class="bg-gray-700 divide-y divide-gray-600">
              <tr class="bg-white">
                <td colspan="4" class="px-6 py-2 whitespace-nowrap text-2xl font-bold text-gray-800">
                  Entries & Usage
                </td>
              </tr>
              <tr>
                <td class="px-6 py-4 whitespace-nowrap text-base font-medium text-gray-200">
                  Entries Included
                </td>
                <td v-for="level in pricing_levels" :key="level.name" class="px-6 py-4 whitespace-nowrap text-base text-gray-300 text-center">
                  {{ level.entries_included }}
                </td>
              </tr>
              <tr>
                <td class="px-6 py-4 whitespace-nowrap text-base font-medium text-gray-200">
                  Additional Entries
                </td>
                <td v-for="level in pricing_levels" :key="level.name" class="px-6 py-4 whitespace-nowrap text-base text-gray-300 text-center">
                  ${{ level.extra_entries.toFixed(2) }}/entry
                  <br><span class="text-xs text-gray-400">*Only applies for each entry over {{ level.entries_included }}*</span>
                </td>
              </tr>
              <tr>
                <td class="px-6 py-4 whitespace-nowrap text-base font-medium text-gray-200">
                  Max Entries
                </td>
                <td v-for="level in pricing_levels" :key="level.name" class="px-6 py-4 whitespace-nowrap text-base text-gray-300 text-center">
                  {{ level.max_entries }}
                </td>
              </tr>
              <!--<tr>
                <td class="px-6 py-4 whitespace-nowrap text-base font-medium text-gray-200">
                  Max Exhibitors
                </td>
                <td v-for="level in pricing_levels" :key="level.name" class="px-6 py-4 whitespace-nowrap text-base text-gray-300 text-center">
                  {{ level.max_exhibitors }}
                </td>
              </tr>-->
              <tr>
                <td class="px-6 py-4 whitespace-nowrap text-base font-medium text-gray-200">
                  Number of Species
                </td>
                <td v-for="level in pricing_levels" :key="level.name" class="px-6 py-4 whitespace-nowrap text-base text-gray-300 text-center">
                  {{ level.species }}
                </td>
              </tr>
              <!--<tr>
                <td class="px-6 py-4 whitespace-nowrap text-base font-medium text-gray-200">
                  Consectutive Days
                </td>
                <td v-for="level in pricing_levels" :key="level.name" class="px-6 py-4 whitespace-nowrap text-base text-gray-300 text-center">
                  {{ level.days }}
                </td>
              </tr>-->
              <tr>
                <td class="px-6 py-4 whitespace-nowrap text-base font-medium text-gray-200">
                  Devices
                </td>
                <td v-for="level in pricing_levels" :key="level.name" class="px-6 py-4 whitespace-nowrap text-base text-gray-300 text-center">
                  {{ level.devices }}
                </td>
              </tr>
              <tr>
                <td class="px-6 py-4 whitespace-nowrap text-base font-medium text-gray-200">
                  Users
                </td>
                <td v-for="level in pricing_levels" :key="level.name" class="px-6 py-4 whitespace-nowrap text-base text-gray-300 text-center">
                  {{ level.users }}
                </td>
              </tr>
              <tr>
                <td colspan="4" class="px-6 py-2 whitespace-nowrap text-2xl font-bold text-gray-800 bg-white">
                  Show Support
                </td>
              </tr>
              <tr>
                <td class="px-6 py-4 whitespace-nowrap text-base font-medium text-gray-200">
                  Dedicated Show Specialist
                </td>
                <td v-for="level in pricing_levels" :key="level.name" class="px-6 py-4 whitespace-nowrap text-base text-gray-300 text-center">
                  <span v-if="level.support.dedicated_show_specialist" class="text-green-500 text-center flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-10 h-10">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.857-9.809a.75.75 0 0 0-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 1 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z" clip-rule="evenodd" />
                    </svg>
                  </span>
                  <span v-else class="text-center flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-10 w-10">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM6.75 9.25a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5h-6.5Z" clip-rule="evenodd" />
                    </svg>
                  </span>
                </td>
              </tr>
              <tr>
                <td class="px-6 py-4 whitespace-nowrap text-base font-medium text-gray-200">
                  Knowledge Base Access
                </td>
                <td v-for="level in pricing_levels" :key="level.name" class="px-6 py-4 whitespace-nowrap text-base text-gray-300 text-center">
                  <span v-if="level.support.knowledge_base_access" class="text-green-500 text-center flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-10 h-10">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.857-9.809a.75.75 0 0 0-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 1 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z" clip-rule="evenodd" />
                    </svg></span>
                  <span v-else class="text-center flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-10 w-10">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM6.75 9.25a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5h-6.5Z" clip-rule="evenodd" />
                    </svg>
                  </span>
                </td>
              </tr>
              <tr>
                <td class="px-6 py-4 whitespace-nowrap text-base font-medium text-gray-200">
                  Video Tutorials
                </td>
                <td v-for="level in pricing_levels" :key="level.name" class="px-6 py-4 whitespace-nowrap text-base text-gray-300 text-center">
                  <span v-if="level.support.video_tutorials" class="text-green-500 text-center flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-10 h-10">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.857-9.809a.75.75 0 0 0-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 1 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z" clip-rule="evenodd" />
                    </svg></span>
                  <span v-else class="text-center flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-10 w-10">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM6.75 9.25a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5h-6.5Z" clip-rule="evenodd" />
                    </svg>
                  </span>
                </td>
              </tr>
              <tr>
                <td class="px-6 py-4 whitespace-nowrap text-base font-medium text-gray-200">
                  Email Support
                </td>
                <td v-for="level in pricing_levels" :key="level.name" class="px-6 py-4 whitespace-nowrap text-base text-gray-300 text-center">
                  <span v-if="level.support.email_support" class="text-green-500 text-center flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-10 h-10">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.857-9.809a.75.75 0 0 0-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 1 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z" clip-rule="evenodd" />
                    </svg></span>
                  <span v-else class="text-center flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-10 w-10">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM6.75 9.25a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5h-6.5Z" clip-rule="evenodd" />
                    </svg>
                  </span>
                </td>
              </tr>
              <tr>
                <td class="px-6 py-4 whitespace-nowrap text-base font-medium text-gray-200">
                  Show Onboarding Call
                </td>
                <td v-for="level in pricing_levels" :key="level.name" class="px-6 py-4 whitespace-nowrap text-base text-gray-300 text-center">
                  <span v-if="level.support.show_setup_call" class="text-green-500 text-center flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-10 h-10">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.857-9.809a.75.75 0 0 0-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 1 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z" clip-rule="evenodd" />
                    </svg>
                  </span>
                  <span v-else class="text-center flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-10 w-10">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM6.75 9.25a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5h-6.5Z" clip-rule="evenodd" />
                    </svg>
                  </span>
                </td>
              </tr>
              <tr>
                <td class="px-6 py-4 whitespace-nowrap text-base font-medium text-gray-200">
                  Premimum Support Hours
                </td>
                <td v-for="level in pricing_levels" :key="level.name" class="px-6 py-4 whitespace-nowrap text-base text-gray-300 text-center">
                  {{ level.support.additional_support_hours }}
                </td>
              </tr>
              <tr>
                <td class="px-6 py-4 whitespace-nowrap text-base font-medium text-gray-200">
                  Day-of Phone Support
                </td>
                <td v-for="level in pricing_levels" :key="level.name" class="px-6 py-4 whitespace-nowrap text-sm text-gray-300 text-center">
                  <span v-if="level.support.day_of_phonenumber" class="text-green-500 text-center flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-10 h-10">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.857-9.809a.75.75 0 0 0-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 1 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z" clip-rule="evenodd" />
                    </svg></span>
                  <span v-else class="text-center flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-10 w-10">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM6.75 9.25a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5h-6.5Z" clip-rule="evenodd" />
                    </svg>
                  </span>
                </td>
              </tr>
              <tr>
                <td colspan="4" class="px-6 py-2 whitespace-nowrap text-2xl font-bold text-gray-800 bg-white">
                  <h3>Online Entries</h3>
                </td>
              </tr>
              <tr v-for="(value, key) in pricing_levels[0].online_entries" :key="key">
                <td class="px-6 py-4 whitespace-nowrap text-base font-medium text-gray-200">
                  {{ key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()) }}
                </td>
                <td v-for="level in pricing_levels" :key="level.name" class="px-6 py-4 whitespace-nowrap text-sm text-gray-300 text-center">
                  <span v-if="level.online_entries[key]" class="text-green-500 text-center flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-10 h-10">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.857-9.809a.75.75 0 0 0-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 1 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z" clip-rule="evenodd" />
                    </svg>
                  </span>
                  <span v-else class="text-center flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-10 w-10">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM6.75 9.25a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5h-6.5Z" clip-rule="evenodd" />
                    </svg>
                  </span>
                </td>
              </tr>
              <tr>
                <td colspan="4" class="px-6 py-2 whitespace-nowrap text-2xl font-bold text-gray-800 bg-white">
                  <h3>Show Day Tools</h3>
                </td>
              </tr>
              <tr v-for="(value, key) in pricing_levels[0].show_day_tools" :key="key">
                <td class="px-6 py-4 whitespace-nowrap text-base font-medium text-gray-200">
                  {{ key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()) }}
                </td>
                <td v-for="level in pricing_levels" :key="level.name" class="px-6 py-4 whitespace-nowrap text-sm text-gray-300 text-center">
                  <span v-if="level.show_day_tools[key]" class="text-green-500 text-center flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-10 h-10">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.857-9.809a.75.75 0 0 0-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 1 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z" clip-rule="evenodd" />
                    </svg>
                  </span>
                  <span v-else class="text-center flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-10 w-10">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM6.75 9.25a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5h-6.5Z" clip-rule="evenodd" />
                    </svg>
                  </span>
                </td>
              </tr>
              <tr>
                <td colspan="4" class="px-6 py-2 whitespace-nowrap text-2xl font-bold text-gray-800 bg-white">
                  <h3>Advanced Features</h3>
                </td>
              </tr>
              <tr v-for="(value, key) in pricing_levels[0].advanced_features" :key="key">
                <td class="px-6 py-4 whitespace-nowrap text-base font-medium text-gray-200">
                  {{ key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()) }}
                </td>
                <td v-for="level in pricing_levels" :key="level.name" class="px-6 py-4 whitespace-nowrap text-sm text-gray-300 text-center">
                  <span v-if="level.advanced_features[key] === true" class="text-green-500 text-center flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-10 h-10">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.857-9.809a.75.75 0 0 0-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 1 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z" clip-rule="evenodd" />
                    </svg>
                  </span>
                  <span v-else-if="level.advanced_features[key] === false" class="text-center flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-10 w-10">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM6.75 9.25a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5h-6.5Z" clip-rule="evenodd" />
                    </svg>
                  </span>
                  <span v-else>
                    {{ level.advanced_features[key] }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>

    <FAQ />

    <div class="bg-indigo-600">
      <div class="max-w-4xl mx-auto pt-8 pb-10 mb-16 ">
        <p class="text-4xl sm:text-5xl text-white font-extrabold text-center tracking-tight">Online Payments</p>
        <p class="text-3xl text-indigo-100 text-center mb-3">What's up with the 3.5% fee?</p>
        <p class="sm:text-lg leading-5 text-center text-white p-2 mb-8">
          Convenience comes at a cost. In order to accept payments via credit card, payment processors charge businesses a small processing fee.
          This is true when getting gas, eating out or shopping online.
          Most businesses build that cost into their prices, while also factoring in the convenience of getting access to their funds sooner and not having to deal with physical cash or checks.
          With Showman you have access to our integrated online payment system, making it easier for exhibitors to pay and for you to get paid.
        </p>

          <div class="text-center tracking-tight sm:mx-0">
            <span class="inline-flex relative">
              <span aria-hidden="" class="absolute inset-0 bg-white opacity-75" style="transform: skewY(-2.6deg) scaleX(1.0) scaleY(1.2);"></span>
              <span class="relative p-4">
                <p class="text-xl sm:text-2xl text-black font-bold tracking-tight mb-2">Worried about paying the fee? You have a few options:</p>
                <div class="text-left px-2 sm:px-12 space-y-2 sm:text-lg">
                  <p>1. Pass the fee along. Exhibitors will have the fee tacked onto their invoice when they checkout.</p>
                  <p>2. Factor the fee into the costs of running the show. Some easy ways to do this are: increase prices by $1, charge a pen fee or get an extra sponsor for your show.
                  </p>
                </div>
              </span>
            </span>
          </div>
      </div>
    </div>

    <div id="testimonials">
        <div class="sm:flex w-full divide-y sm:divide-y-0 sm:divide-x divide-dashed divide-gray-400 my-12 text-center">
          <div class="sm:w-1/2 md:w-1/3">
            <div class="px-6 py-12 text-lg lg:text-xl leading-6 lg:leading-7">
              <img src="https://res.cloudinary.com/showman/image/upload/w_80/v1655138321/Testimonial_The_Classic.png" width="400" height="400" class="block w-20 h-20 rounded-lg mx-auto" alt="Chris" loading="lazy">
              <p class="flex-grow py-6 text-gray-200 lg:tracking-tight">“I cannot express to you enough how well Showman worked for our show. Your customer service was absolutely amazing and the way you were able to customize your program to fit our show was spectacular. We had a very smooth ran show and grossed more than ever before!”</p>
              <div class="text-lg text-white font-medium">The Classic/AGR Spring Showdown</div>
              <div class="text-base text-gray-300">Iowa State University Block & Bridle and AGR</div>
            </div>
          </div>

          <div class="sm:w-1/2 md:w-1/3">
            <div class="px-6 py-12 text-lg lg:text-xl leading-6 lg:leading-7">
              <img src="https://res.cloudinary.com/showman/image/upload/w_80/v1655138704/Testimonial_Boatman.png" width="400" height="400" class="block w-20 h-20 rounded-lg mx-auto" alt="Tracy" loading="lazy">
              <p class="flex-grow py-6 text-gray-200 lg:tracking-tight">“Just wanted to say thank you. The Show went off without a hitch. We'll be back next year! The app saved me a ton of time and stress! ”</p>
              <div class="text-lg text-white font-medium">Wendi Boatman</div>
              <div class="text-base text-gray-300">Boatman Club Lambs</div>
            </div>
          </div>

          <div class="sm:w-1/2 md:w-1/3">
            <div class="px-6 py-12 text-lg lg:text-xl leading-6 lg:leading-7">
              <img src="https://res.cloudinary.com/showman/image/upload/w_80/v1655139172/Testimonial_Mitten.png" width="400" height="400" class="block w-20 h-20 rounded-lg mx-auto" alt="Matt" loading="lazy">
              <p class="flex-grow py-6 text-gray-200 lg:tracking-tight">“We won’t put on a show without it! Having Showman kept our records clean and our show organized.
                    We were able to compile and finalize our show results the day after the show!
                    Thank you for developing this tool that will be so helpful to many!”</p>
              <div class="text-lg text-white font-medium">Lisa Douglas & Jodie Fath</div>
              <div class="text-base text-gray-300">Central Michigan Boer Goat Board Members after their first time hosting a livestock show</div>
            </div>
          </div>
        </div>
      </div>

    <div class="bg-gray-900">
      <div class="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <h2 class="text-3xl font-extrabold tracking-tight text-gray-100 sm:text-4xl">
          <span class="block">Ready to get started on your show?</span>
        </h2>
        <div class="mt-8 flex justify-center">
          <div class="inline-flex rounded-lg shadow">
            <a onclick="fathom.trackGoal('PPBJ1VUV', 0);" href="/shows/#/new-show-signup" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-bold rounded-lg text-white bg-showman hover:bg-orange-500">
              Start my show set up!
            </a>
          </div>

        </div>
        <p onclick="fathom.trackGoal('HIJDFCOU', 0);" class="text-gray-100 mt-8">Still need to see more? <a class="link font-bold" data-savvycal-embed href="https://savvycal.com/showman/demo">Schedule a demo</a></p>
      </div>
    </div>

    <div class="max-w-6xl mx-auto mt-16 pb-16 sm:pb-20 lg:pb-28 px-4 sm:px-6 lg:px-8">
        <h4 class="flex-shrink-0 pr-4 text-lg tracking-wider font-semibold uppercase text-white">
          Available Add On's
        </h4>
        <div class="flex-1 border-t-2 border-gray-300"></div>
        <div class="relative mt-4 lg:mt-8 lg:flex lg:gap-8 lg:items-center">
          <div class="relative lg:w-1/2">
            <h3 class="text-xl font-extrabold tracking-tight sm:text-2xl text-gray-100">
              Integrated Sponsorship Form
            </h3>
            <p class="mt-3 text-lg text-gray-300">
               Collect show sponsors information and payments right from your show homepage.
            </p>

            <div class="py-8 px-4 sm:py-16 sm:px-6 lg:bg-none lg:px-0 lg:pl-8 lg:flex lg:items-center lg:justify-end">
              <div class="max-w-lg mx-auto w-full space-y-8 lg:mx-0">
                <div>
                  <h2 class="sr-only">Price</h2>
                  <h2 class="text-3xl text-white text-center font-bold mb-2">Get sponsors while you sleep</h2>
                  <p class="relative grid grid-cols-2">
                    <span class="flex flex-col text-center">
                      <span class="text-5xl font-extrabold text-white tracking-tight line-through ">1.5%</span>
                      <span class="mt-2 text-base font-medium text-indigo-200"><span class="font-bold">NEW FOR 2022</span><br> No additional fees for collecting sponsors</span>
                      <span class="sr-only">plus</span>
                    </span>
                    <span class="pointer-events-none absolute h-12 w-full flex items-center justify-center" aria-hidden="true">
                      <svg class="h-6 w-6 text-indigo-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                      </svg>
                    </span>
                    <span>
                      <span class="flex flex-col text-center">
                        <span class="text-5xl font-extrabold text-white tracking-tight">3.5%</span>
                        <span class="mt-2 text-base font-medium text-indigo-200">CC Processing</span>
                      </span>
                    </span>
                  </p>
                  <!--<p class="text-center text-sm text-indigo-200 mt-3">
                    (Of sponsorship money collected through Showman)
                  </p>-->
                </div>
                <ul role="list" class="rounded overflow-hidden grid gap-px sm:grid-cols-2">
                  <li class="bg-indigo-800 bg-opacity-50 py-4 px-4 flex items-center space-x-3 text-base text-white">
                    <!-- Heroicon name: outline/check -->
                    <svg class="h-6 w-6 text-indigo-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                    <span>Unlimited sponsor levels</span>
                  </li>

                  <li class="bg-indigo-800 bg-opacity-50 py-4 px-4 flex items-center space-x-3 text-base text-white">
                    <!-- Heroicon name: outline/check -->
                    <svg class="h-6 w-6 text-indigo-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                    <span>No cost to set up</span>
                  </li>

                  <li class="bg-indigo-800 bg-opacity-50 py-4 px-4 flex items-center space-x-3 text-base text-white">
                    <!-- Heroicon name: outline/check -->
                    <svg class="h-6 w-6 text-indigo-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                    <span>CC processing fees can be built in or passed on</span>
                  </li>

                  <li class="bg-indigo-800 bg-opacity-50 py-4 px-4 flex items-center space-x-3 text-base text-white">
                    <!-- Heroicon name: outline/check -->
                    <svg class="h-6 w-6 text-indigo-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                    <span>Sponsors are automatically added to show homepage</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="lg:w-1/2 mt-10 lg:pl-16 -mx-4 relative lg:mt-0" aria-hidden="true">
            <img class="relative mx-auto rounded-lg shadow-lg px-4 md:px-0" width="490" src="https://res.cloudinary.com/showman/image/upload/c_scale,w_490,f_auto/v1633366701/sponsor_show_screenshot.png" alt="">
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import FAQ from './FAQ.vue'
import ShowmanAuctionPricing from './ShowmanAuctionPricing.vue'
export default {
  components: {
    FAQ,
    ShowmanAuctionPricing
  },
  data () {
    return {
      supportEmail: process.env.VUE_APP_SUPPORT_EMAIL,
      pricing_levels: [
        {
          name: 'Starter',
          price: 299,
          entries_included: 150,
          extra_entries: 1,
          max_entries: 250,
          max_exhibitors: 50,
          support_hours: 1,
          species: 2,
          days: 2,
          devices: 'Unlimited',
          users: 'Unlimited',
          support: {
            dedicated_show_specialist: true,
            knowledge_base_access: true,
            video_tutorials: true,
            email_support: true,
            show_setup_call: true,
            additional_support_hours: 0,
            day_of_phonenumber: true
          },
          online_entries: {
            online_payments: true,
            sponsorships: true,
            custom_entry_questions: true,
            apparel_orders: true
          },
          show_day_tools: {
            check_in_tools: true,
            exhibitors_add_declared_weights: true,
            break_classes: true,
            print_out_show_books: true,
            publish_online_showbooks: true,
            print_back_tags: true,
            record_results: true,
            publish_online_results: true
          },
          advanced_features: {
            static_exhibits: false,
            ribbon_placings: false,
            premium_calculations: 'Add-On',
            premier_exhibitor_calculation: false,
            auction_add_on_available: false
          }
        },
        {
          name: 'Standard',
          price: 399,
          entries_included: 1000,
          extra_entries: 0.40,
          max_entries: 'Unlimited',
          max_exhibitors: 'Unlimited',
          support_hours: 2,
          species: 5,
          days: 4,
          devices: 'Unlimited',
          users: 'Unlimted',
          support: {
            dedicated_show_specialist: true,
            show_setup_call: true,
            knowledge_base_access: true,
            video_tutorials: true,
            email_support: true,
            additional_support_hours: 1,
            day_of_phonenumber: true
          },
          online_entries: {
            online_payments: true,
            sponsorships: true,
            custom_entry_questions: true,
            apparel_orders: true
          },
          show_day_tools: {
            check_in_tools: true,
            exhibitors_add_declared_weights: true,
            break_classes: true,
            print_out_show_books: true,
            publish_online_showbooks: true,
            print_back_tags: true,
            record_results: true,
            publish_online_results: true
          },
          advanced_features: {
            static_exhibits: false,
            ribbon_placings: false,
            premium_calculations: 'Add-On',
            premier_exhibitor_calculation: 'Add-On',
            auction_add_on_available: 'Add-on'
          }
        },
        {
          name: 'Elite',
          price: 699,
          entries_included: 1000,
          extra_entries: 0.40,
          max_entries: 'Unlimited',
          max_exhibitors: 'Unlimited',
          species: 'Unlimited',
          days: 'Unlimited',
          devices: 'Unlimited',
          users: 'Advanced Department Controls',
          support: {
            dedicated_show_specialist: true,
            show_setup_call: true,
            knowledge_base_access: true,
            video_tutorials: true,
            email_support: true,
            additional_support_hours: 2,
            day_of_phonenumber: true
          },
          online_entries: {
            online_payments: true,
            sponsorships: true,
            custom_entry_questions: true,
            apparel_orders: true
          },
          show_day_tools: {
            check_in_tools: true,
            exhibitors_add_declared_weights: true,
            break_classes: true,
            print_out_show_books: true,
            publish_online_showbooks: true,
            print_back_tags: true,
            record_results: true,
            publish_online_results: true
          },
          advanced_features: {
            static_exhibits: true,
            ribbon_placings: true,
            premium_calculations: 'Add-On',
            premier_exhibitor_calculation: 'Add-On',
            auction_add_on_available: 'Add-on'
          }
        }
      ]
    }
  }
}
</script>

<style>

</style>
